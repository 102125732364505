<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.disablePerson') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          :icon-name="icons.close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px;"
      >
        <v-card-text-subtitle
          v-if="!$vuetify.breakpoint.mobile"
          class="caption text-sm-body-2"
        >
          {{ $t('deconve.person.selectReasonToDisablePerson') }}
        </v-card-text-subtitle>
        <v-radio-group
          v-model="disableOptionSelected"
          style="gap: 5px"
          class="caption text-sm-body-2"
        >
          <v-radio
            v-for="(item, index) in disableOptions"
            :key="index"
            :label="$t(item.label)"
            :value="item.value"
          />
        </v-radio-group>
      </v-card-text>

      <v-card-actions
        class="responsiveButton"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @click="cancel"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>

        <v-btn
          color="primary"
          :disabled="enableConfirmButton"
          @click="submit"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';

import { mapActions } from 'vuex';
import SquareButton from './SquareButton.vue';

export default {
  name: 'DisablePersonDialog',
  components: {
    SquareButton,
  },
  data: () => ({
    personId: null,
    dialog: false,
    isLoading: false,
    disableOptions: [
      { label: 'deconve.person.lgpdViolation', value: 'lgpd_violation' },
      { label: 'deconve.person.lowQualityImage', value: 'low_quality_image' },
      { label: 'deconve.person.excessiveFalseAlerts', value: 'excessive_false_alerts' },
    ],
    disableOptionSelected: null,
    icons: {
      close: mdiClose,
    },
  }),
  computed: {
    enableConfirmButton() {
      return !this.disableOptionSelected;
    },
  },
  methods: {
    ...mapActions({
      changePersonActivation: 'faceid/changePersonActivation',
    }),
    open({ personId }) {
      this.dialog = true;
      this.personId = personId;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    submit() {
      this.isLoading = true;

      this.changePersonActivation({
        personId: this.personId,
        disableOptionSelected: this.disableOptionSelected,
      })
        .then(() => {
          this.resolve(true);
          this.dialog = false;
        })
        .catch(() => {
          this.reject();
          this.dialog = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    cancel() {
      this.resolve(true);
      this.dialog = false;
    },
  },
};
</script>
