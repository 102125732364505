<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '60%'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-8 py-4 d-flex flex-column"
      variant="elevated"
      width="100%"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.person.similarPeople') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          :icon-name="icons.mdiClose"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="close"
        />
      </v-toolbar>

      <profile-list-viewer
        :profiles-to-show="similarPeopleIds"
        :is-loading="isLoading"
      />

      <v-card-actions class="mt-4">
        <v-spacer />
        <v-btn
          v-if="isRegister"
          type="button"
          color="neutral"
          outlined
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          :x-small="$vuetify.breakpoint.mobile"
          @click="cancel"
        >
          {{ $t('deconve.cancelRegistration') }}
        </v-btn>

        <v-btn
          type="button"
          color="primary"
          :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
          elevation="0"
          class="d-flex custom-transform-class text-none px-3"
          :x-small="$vuetify.breakpoint.mobile"
          @click="close"
        >
          {{ $t('deconve.continue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import SquareButton from './SquareButton.vue';
import ProfileListViewer from './ProfileListViewer.vue';

export default {
  name: 'SimilarPeopleDialog',
  components: {
    SquareButton,
    ProfileListViewer,
  },
  props: {
    isLoading: Boolean,
  },
  data: () => ({
    dialog: false,
    isRegister: false,
    icons: {
      mdiClose,
    },
    similarPeople: [],
  }),
  computed: {
    similarPeopleIds() {
      return this.similarPeople.map((item) => ({ id: item.person.id }));
    },
  },
  created() {
    const personId = this.$route.params?.personId;

    if (!personId) {
      this.isRegister = true;
    }
  },
  methods: {
    open(similarPeople) {
      this.similarPeople = similarPeople;

      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      return this.$router.push({ name: 'faceid-people' });
    },
  },
};
</script>
