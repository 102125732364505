<template>
  <v-card
    class="d-flex align-start"
    elevation="0"
  >
    <v-card
      :style="{
        height: $vuetify.breakpoint.mobile? '80px' : '100px',
        width: $vuetify.breakpoint.mobile? '60px' : '80px',
        overflow: 'hidden',
        padding: '2px',
      }"
      elevation="0"
      @click="$emit('onFaceSelected', index)"
    >
      <v-img
        ref="face"
        :src="croppedImage"
        class="rounded grey lighten-2 d-flex align-end "
        style="width: 100%; height: 100%; object-fit: cover"
      />
    </v-card>
    <div>
      <v-tooltip
        v-if="!loadingSimilar && similarPeople.length > 0"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="warning"
            class="ma-1 cursor-pointer"
            style="cursor: pointer;"
            :small="$vuetify.breakpoint.mobile"
            v-bind="attrs"
            v-on="on"
            @click="openSimilarPeopleDialog"
          >
            {{ icons.mdiAlert }}
          </v-icon>
        </template>
        <span>
          {{ $tc('deconve.person.identifiedPeople', similarPeople.length) }}
        </span>
      </v-tooltip>

      <template v-else-if="loadingSimilar">
        <span class="fill-height ma-0">
          <v-progress-circular
            size="16"
            width="2"
            indeterminate
            color="grey lighten-1"
          />
        </span>
      </template>
    </div>

    <similar-people-dialog
      ref="similarDialog"
      :is-loading="loadingSimilar"
    />
  </v-card>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';
import { mdiAlert } from '@mdi/js';
import SimilarPeopleDialog from '@/components/SimilarPeopleDialog.vue';

export default {
  name: 'FaceListContent',
  components: {
    SimilarPeopleDialog,
  },
  props: {
    face: { type: String, required: true },
    info: { type: Object, required: true },
    originalHeight: { type: Number, required: true },
    originalWidth: { type: Number, required: true },
    index: { type: Number, required: true },
    similarPeople: { type: Array, required: true },
    enableSimilarPersonSearch: { type: Boolean, required: true },
  },
  data: () => ({
    croppedImage: '',
    loadingSimilar: false,
    icons: {
      mdiAlert,
    },
  }),
  watch: {
    face() {
      this.cropFace();
    },
    croppedImage() {
      if (this.enableSimilarPersonSearch) {
        this.loadingSimilar = true;
        this.searchDuplicatePeople();
      }
    },
    enableSimilarPersonSearch() {
      if (this.enableSimilarPersonSearch) {
        this.loadingSimilar = true;
        this.searchDuplicatePeople();
      }
    },
  },
  created() {
    this.cropFace();
  },
  methods: {
    ...mapActions({
      fetchDuplicatePeopleByImage: 'faceid/fetchDuplicatePeopleByImage',
    }),
    cropFace() {
      const img = new Image();
      const boundingBox = this.info.bounding_box;

      img.onload = (face) => {
        const { width, height } = img;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const originalWidth = this.originalWidth || width;
        const originalHeight = this.originalHeight || height;

        const aspectRatio = boundingBox.width / boundingBox.height;

        canvas.width = 80 * aspectRatio;
        canvas.height = 80;

        const percentageWidth = boundingBox.width / originalWidth;
        const percentageHeight = boundingBox.height / originalHeight;

        const resizedWidth = width * percentageWidth;
        const resizedHeight = height * percentageHeight;

        const percentageX = boundingBox.top_left_x / originalWidth;
        const percentageY = boundingBox.top_left_y / originalHeight;

        const resizedXPos = width * percentageX;
        const resizedYPos = height * percentageY;

        const imageOffsetXPos = 0;
        const imageOffsetYPos = 0;

        ctx.drawImage(
          face.target,
          resizedXPos,
          resizedYPos,
          resizedWidth,
          resizedHeight,
          imageOffsetXPos,
          imageOffsetYPos,
          canvas.width,
          canvas.height,
        );

        const resizedImage = ctx.canvas.toDataURL(face.target, 'image/jpeg', 0);

        this.croppedImage = resizedImage;
      };

      img.src = this.face;
    },
    searchDuplicatePeople() {
      const imageData = { image: this.croppedImage, imageIndex: this.index };

      this.fetchDuplicatePeopleByImage(imageData).then(() => {
        this.loadingSimilar = false;
      }).catch(() => {
        this.loadingSimilar = false;
      });
    },
    openSimilarPeopleDialog() {
      this.$refs.similarDialog.open(this.similarPeople);
    },
  },
};
</script>
