// Copyright (C) 2024 Deconve Technology. All rights reserved.

// eslint-disable-next-line import/prefer-default-export
export const FALSE_NEGATIVE_STATUS = {
  stole: 'stole',
  // eslint-disable-next-line @typescript-eslint/camelcase
  tried_to_steal: 'tried_to_steal',
  // eslint-disable-next-line @typescript-eslint/camelcase
  did_not_try_to_steal: 'did_not_try_to_steal',
};
