<template>
  <v-alert
    :type="type"
    border="left"
    :color="color"
    text
    outlined
    style="flex: 1"
    class="caption text-sm-body-2"
  >
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    color: { type: String, default: '' },
    type: { type: String, default: '' },
  },
};
</script>
