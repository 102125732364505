<template>
  <v-col class="mt-6">
    <v-row>
      <alert
        color="info"
        type="info"
      >
        {{ $t('deconve.person.alerts.notificationStats') }}
      </alert>
    </v-row>
    <v-row
      style="height: fit-content"
      class="d-flex flex-column flex-sm-row"
    >
      <v-card
        class="d-flex flex-column pa-6"
        style="border: 1px solid; flex: 1"
        max-width="400px"
        :style="{borderColor: $vuetify.theme.themes.light.border}"
        elevation="0"
      >
        <div class="d-flex align-center">
          <v-icon
            class="mr-2"
            color="neutralPrimary"
            :size="$vuetify.breakpoint.mobile? 20 : 24"
          >
            {{ icons.bell }}
          </v-icon>
          <span
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >{{ $t('deconve.allNotifications') }}</span>
        </div>
        <span
          class="
            d-flex
            align-center
            justify-center
            text-h1
            font-weight-bold
            neutralPrimary--text
            py-4
          "
          style="flex: 1"
        >{{ stats.total }}</span>
        <rectangle-button
          color="primary"
          style="width: 100%"
          :disabled="isLoading"
          @clicked="viewMore(NOTIFICATION_REVIEW_STATUS.all)"
        >
          {{ $t('deconve.viewMore') }}
        </rectangle-button>
      </v-card>
      <div style="width: 12px; height: 12px" />
      <div style="flex: 1; min-width: 200px">
        <v-card
          class="d-flex align-center justify-space-between pa-6"
          width="100%"
          height="100px"
          style="border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          elevation="0"
        >
          <div class="d-flex">
            <div
              class="d-flex align-center justify-center positive lighten-4 rounded-circle mr-6"
              style="height: 52px; width: 52px"
            >
              <v-icon color="positive">
                {{ icons.check }}
              </v-icon>
            </div>
            <div class="d-flex align-center">
              <span class="text-h4 font-weight-bold mr-3">{{ stats.positive }}</span>
              <span>{{ $tc(
                'deconve.report.positiveNotifications2',
                stats.positives,
                {n: stats.positives}
              ) }}</span>
            </div>
          </div>
          <square-button
            menu-disabled
            icon-name="mdi-chevron-right"
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            :disabled="isLoading"
            @clicked="viewMore(NOTIFICATION_REVIEW_STATUS.yes)"
          />
        </v-card>
        <v-card
          class="d-flex align-center justify-space-between pa-6 mt-3"
          width="100%"
          height="100px"
          style="border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          elevation="0"
        >
          <div class="d-flex">
            <div
              class="d-flex align-center justify-center negative lighten-4 rounded-circle mr-6"
              style="height: 52px; width: 52px"
            >
              <v-icon color="negative">
                {{ icons.close }}
              </v-icon>
            </div>

            <div class="d-flex align-center">
              <span class="text-h4 font-weight-bold mr-3">{{ stats.negative }}</span>
              <span>{{ $tc(
                'deconve.report.negativeNotifications',
                stats.negatives,
                {n: stats.negatives}
              ) }}</span>
            </div>
          </div>
          <square-button
            menu-disabled
            icon-name="mdi-chevron-right"
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            :disabled="isLoading"
            @clicked="viewMore(NOTIFICATION_REVIEW_STATUS.no)"
          />
        </v-card>
        <v-card
          class="d-flex align-center justify-space-between pa-6 mt-3"
          width="100%"
          height="100px"
          style="border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          elevation="0"
        >
          <div class="d-flex">
            <div
              class="d-flex align-center justify-center neutral lighten-4 rounded-circle mr-6"
              style="height: 52px; width: 52px"
            >
              <v-icon color="neutral">
                {{ icons.help }}
              </v-icon>
            </div>
            <div class="d-flex align-center">
              <span class="text-h4 font-weight-bold mr-3">{{ stats.unreviewed }}</span>
              <span>{{ $tc(
                'deconve.report.unreviewedNotifications',
                stats.unreviewed,
                {n: stats.unreviewed}
              ) }}</span>
            </div>
          </div>
          <square-button
            menu-disabled
            icon-name="mdi-chevron-right"
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            :disabled="isLoading"
            @clicked="viewMore(NOTIFICATION_REVIEW_STATUS.unreviewed)"
          />
        </v-card>
      </div>
      <v-card
        class="d-flex align-center justify-space-between pa-6 mt-3"
        width="100%"
        height="100px"
        style="border: 1px solid"
        :style="{borderColor: $vuetify.theme.themes.light.border}"
        elevation="0"
      >
        <div class="d-flex">
          <div
            class="d-flex align-center justify-center neutral lighten-4 rounded-circle mr-6"
            style="height: 52px; width: 52px"
          >
            <v-icon color="neutral">
              {{ icons.alert }}
            </v-icon>
          </div>
          <div class="d-flex align-center">
            <span class="text-h4 font-weight-bold mr-3">{{ personFalseNegatives }}</span>
            <span>{{ $tc(
              'deconve.falseNegativeReported',
              personFalseNegatives,
              {n: personFalseNegatives}
            ) }}</span>
          </div>
        </div>
        <square-button
          menu-disabled
          icon-name="mdi-chevron-right"
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          :disabled="isLoading"
          @clicked="openReportFalseNegativeDialog"
        />
      </v-card>
    </v-row>
    <v-row>
      <v-card
        v-for="(reviewSettings, i) in notificationReviewSettings"
        :key="reviewSettings.video.id"
        class="d-flex align-center justify-space-between pa-6 mt-3"
        width="100%"
        height="100%"
        style="border: 1px solid"
        :style="{borderColor: $vuetify.theme.themes.light.border}"
        elevation="0"
      >
        <div class="d-flex flex-wrap">
          <div
            class="d-flex align-center justify-center neutral lighten-4 rounded-circle mr-6"
            style="height: 52px; width: 52px"
          >
            <v-icon color="neutral">
              {{ icons.bellOff }}
            </v-icon>
          </div>
          <div class="d-flex align-start flex-column">
            <span>
              {{ getReviewInfo(reviewSettings) }}
            </span>
            <span>
              <span> {{ $t('deconve.by') }} <b>
                {{ userNames[i] }}</b>
              </span>
            </span>
          </div>
        </div>
        <div
          class="d-flex align-center justify-center"
        >
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          />
          <square-button
            v-if="!isLoading"
            menu-disabled
            icon-name="mdi-delete"
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            :disabled="isLoading"
            @clicked="deleteReviewSettings(reviewSettings.video.id)"
          />
        </div>
      </v-card>
    </v-row>
    <report-false-negative-dialog ref="reportFalseNegativeDialog" />
  </v-col>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiBellOutline, mdiCheck, mdiClose,
  mdiHelpCircleOutline, mdiAlertCircleOutline, mdiBellOffOutline,
} from '@mdi/js';
import RectangleButton from '@/components/RectangleButton.vue';
import SquareButton from '@/components/SquareButton.vue';
import Alert from '@/components/Alert.vue';
import ReportFalseNegativeDialog from '@/components/ReportFalseNegativeDialog.vue';
import { getPersonNotificationStatistics } from '@/api/report';
import { NOTIFICATION_REVIEW_STATUS } from '@/utils/faceidFilters';
import { getUserInfo } from '@/api/index';

export default {
  name: 'PersonNotifications',
  components: {
    RectangleButton,
    SquareButton,
    Alert,
    ReportFalseNegativeDialog,
  },
  props: {
    personId: { type: String, required: true },
    personName: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      videoId: null,
      userNames: [],
      stats: {},
      personFalseNegatives: 0,
      NOTIFICATION_REVIEW_STATUS,
      icons: {
        bell: mdiBellOutline,
        check: mdiCheck,
        help: mdiHelpCircleOutline,
        alert: mdiAlertCircleOutline,
        bellOff: mdiBellOffOutline,
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      getVideo: 'faceid/getVideo',
      getPerson: 'faceid/getPerson',
    }),
    notificationReviewSettings() {
      const { review_settings: reviewSettings } = this.getPerson(this.personId);

      return reviewSettings || [];
    },
  },
  created() {
    this.getPersonStats();
    this.getUserInfoById();
    this.getPersonFalseNegatives();
  },
  methods: {
    ...mapActions({
      patchPersonProfile: 'faceid/patchPersonProfile',
      deletePersonReviewSettings: 'faceid/deletePersonReviewSettings',
      fetchPersonFalseNegatives: 'faceid/fetchPersonFalseNegatives',
    }),
    getPersonStats() {
      this.isLoading = true;
      getPersonNotificationStatistics(this.personId).then((response) => {
        this.stats = response;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    getUserInfoById() {
      this.isLoading = true;
      this.notificationReviewSettings.forEach((reviewSettings) => {
        getUserInfo(reviewSettings.created_by).then(({ name }) => {
          this.userNames.push(name);
        });
      });
      this.isLoading = false;
    },
    getPersonFalseNegatives() {
      this.isLoading = true;
      this.fetchPersonFalseNegatives(this.personId).then((personFalseNegatives) => {
        this.personFalseNegatives = (personFalseNegatives && personFalseNegatives.length) || 0;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    deleteReviewSettings(videoIdToDelete) {
      this.isLoading = true;

      this.deletePersonReviewSettings({ personId: this.personId, videoIdToDelete }).then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    viewMore(reviewStatus) {
      this.$router.push({
        name: 'faceid-notifications',
        params: {
          reviewStatus,
          status: 'all',
          personId: this.personId,
          personName: this.personName,
        },
      });
    },
    openReportFalseNegativeDialog() {
      this.$refs.reportFalseNegativeDialog.open({
        personId: this.personId,
      });
    },
    formatPersonReviewSettingsDate(date) {
      const reviewSettingsDate = new Date(date);
      const formattedReviewSettingsDate = this.$moment(reviewSettingsDate).format('DD/MM/YYYY HH:mm');

      return formattedReviewSettingsDate;
    },
    getVideoName(videoId) {
      const video = this.getVideo(videoId);

      return video?.name || videoId;
    },
    getReviewInfo(reviewSettings) {
      return this.$t('deconve.disabledNotificationsUntil',
        {
          0: this.formatPersonReviewSettingsDate(reviewSettings.until),
          1: this.getVideoName(reviewSettings.video.id),
        });
    },
  },
};
</script>
