<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="close"
  >
    <v-card
      class="px-6 py-8"
    >
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
        class="align-center justify-space-between"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.selectProfiles') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="close"
        />
      </v-toolbar>

      <v-card-text
        class="pa-4"
      >
        <v-progress-linear
          :active="scrollLoading"
          :indeterminate="scrollLoading"
          absolute
          top
        />
        <input-text
          v-model="filterOptions.search"
          left-icon="mdi-magnify"
          :placeholder="$t('deconve.search')"
        />
        <div
          v-if="scrollLoading || !isLoading"
          class="mb-2 mt-6"
          style="max-height: 280px; overflow-y: auto;"
          @scroll="checkScrollEnd"
        >
          <v-treeview
            class="relatedProfilesTreeview"
            :search="search"
            :items="filteredPersonList"
            item-text="name"
            multiple-active
            activatable
            selected-color="primary"
            :active-class="
              selectedProfiles.length > 0 ? 'v-treeview-node--active' : 'textBlack--text'
            "
            :active="selectedProfiles"
            @update:active="selectPerson"
          >
            <template
              v-slot:label="{ item }"
            >
              <person-preview-line
                :person-id="item.id"
              />
            </template>
          </v-treeview>
        </div>
      </v-card-text>

      <v-card-text
        v-if="selectedProfiles.length > 0"
      >
        <div
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutral--text"
        >
          {{ $t('deconve.selectedProfiles') }}
        </div>
        <div
          class="d-flex flex-wrap"
        >
          <v-chip
            v-for="(selectedPersonId, index) in selectedProfiles"
            :key="selectedPersonId"
            class="mx-2 my-1"
            close
            close-icon="mdi-close"
            label
            outlined
            @click:close="removeSelectedPerson(index)"
          >
            <div class="chip-text">
              {{ getPersonNameById(selectedPersonId) }}
            </div>
          </v-chip>
        </div>
      </v-card-text>

      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          @click="close"
        >
          {{ $t('deconve.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="confirm"
        >
          {{ $t('deconve.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { defaultPeopleFilterOptions } from '@/utils/faceidFilters';
import InputText from '@/components/InputText.vue';
import SquareButton from './SquareButton.vue';
import PersonPreviewLine from './PersonPreviewLine.vue';

export default {
  name: 'RelatedProfilesDialog',
  components: {
    InputText,
    SquareButton,
    PersonPreviewLine,
  },
  props: {
    personId: { type: String, default: '' },
    workspaceId: { type: String, default: undefined },
    isOnDeconveHub: { type: Boolean, default: false },
  },
  data() {
    return {
      filterOptions: defaultPeopleFilterOptions(),
      dialog: false,
      personList: [],
      selectedProfiles: [],
      options: {
        color: 'white',
        width: 480,
        height: 150,
      },
      isLoading: false,
      scrollLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getPerson: 'faceid/getPerson',
    }),
    search() {
      return this.filterOptions.search;
    },
    filteredPersonList() {
      return this.personList?.filter((person) => person.id !== this.personId);
    },
    getPersonNameById() {
      return (personId) => {
        const { name } = this.getPerson(personId);

        return name;
      };
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
  },
  watch: {
    search() {
      this.onFilterChanged();
    },
    workspaceId() {
      if (this.isOnDeconveHub && this.workspaceId) {
        this.filterOptions.workspaceId = this.workspaceId;
        this.personList = [];
        this.onFilterChanged();
      }
    },
  },
  created() {
    if (!this.isOnDeconveHubWorkspace) {
      this.onFilterChanged();
    }
  },
  methods: {
    ...mapActions({
      fetchRelatedPeople: 'faceid/fetchRelatedPeople',
    }),
    checkScrollEnd(event) {
      const diff = event.target.scrollHeight - event.target.scrollTop;
      const bottom = diff === event.target.clientHeight;

      if (bottom) {
        this.scrollLoading = true;
        const newPage = this.filterOptions.page + 1;

        this.filterOptions.page = newPage;

        this.onFilterChanged();
      }
    },
    open(profiles) {
      if (profiles.length > 0) {
        this.selectedProfiles = profiles;

        profiles.forEach((profileId) => {
          if (!this.personList.find((person) => person.id === profileId)) {
            const personName = this.getPersonNameById(profileId);

            this.personList.unshift({ id: profileId, name: personName });
          }
        });
      }

      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    selectPerson(profiles) {
      this.selectedProfiles = profiles;
    },
    reset() {
      this.selectedProfiles = [];
    },
    close() {
      this.dialog = false;
      this.reset();
      this.reject();
    },
    confirm() {
      this.resolve(this.selectedProfiles);
      this.reset();
      this.dialog = false;
    },
    removeSelectedPerson(index) {
      if (this.selectedProfiles[index]) {
        this.selectedProfiles.splice(index, 1);
      }
    },
    handleFetchRelatedPeople() {
      this.isLoading = true;

      this.fetchRelatedPeople(this.filterOptions).then((personListPreview) => {
        this.addNewPeopleOnPersonList(personListPreview);
        this.isLoading = false;
        this.scrollLoading = false;
      }).catch(() => {
        this.isLoading = false;
        this.scrollLoading = false;
      });
    },
    onFilterChanged() {
      this.handleFetchRelatedPeople();
    },
    addNewPeopleOnPersonList(personListPreview) {
      const { items: newPeople } = personListPreview;
      const isListEmpty = !this.personList?.length > 0;

      if (isListEmpty) {
        this.personList = newPeople;
      } else {
        newPeople.forEach((newPerson) => {
          const isOnOlderList = this.personList?.find((person) => newPerson.id === person.id);

          if (!isOnOlderList) {
            this.personList.push(newPerson);
          }
        });
      }
    },
  },
};

</script>

<style>
  .relatedProfilesTreeview .v-treeview-node__level {
    width: 0px;
    cursor: pointer;
  }
  .relatedProfilesTreeview .v-treeview-node--active {
    margin-bottom: 4px;
  }
  .chip-text {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
