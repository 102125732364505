<template>
  <v-menu
    v-model="isMenuActive"
    style="flex: 1"
    offset-y
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        :style="{
          borderColor: activeBorderColor || $vuetify.theme.themes.light.border,
          backgroundColor: $vuetify.theme.themes.light.inputBackground,
          minWidth,
          height: $vuetify.breakpoint.mobile? '36px' : '40px'
        }"
        class="input-container"
        :class="inputClass"
        v-on="on"
      >
        <input
          :placeholder="placeholder"
          class="input-text body-2 text-sm-body-1 textBlack--text"
          style="width: 100%"
          :value="options[optionSelected]"
          readonly
        >
        <div
          class="icon-content"
        >
          <v-icon
            :color="activeIconColor"
            :small="$vuetify.breakpoint.mobile"
            :style="isMenuActive && {transform: 'rotate(-180deg)'}"
          >
            mdi-chevron-down
          </v-icon>
        </div>
      </div>
    </template>
    <v-list
      style="max-height: 280px; overflowY: auto"
      class="content"
    >
      <v-list-item-group v-model="optionSelected">
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
        >
          <v-list-item-title>{{ option }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'InputOptions',
  props: {
    placeholder: { type: String, default: '' },
    inputClass: { type: String, default: '' },
    options: { type: Array, default: null },
    value: { type: Number, default: undefined },
    minWidth: { type: String, default: '' },
  },
  data: () => ({
    isMenuActive: false,
  }),
  computed: {
    optionSelected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    activeBorderColor() {
      return this.isMenuActive
        ? this.$vuetify.theme.themes.light.primary
        : this.$vuetify.theme.themes.light.border;
    },
    activeIconColor() {
      return this.isMenuActive
        ? this.$vuetify.theme.themes.light.primary
        : this.$vuetify.theme.themes.light.neutral;
    },
  },
};
</script>

<style scoped>
.content::-webkit-scrollbar{
  width: 0px;
}
  .input-container{
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    flex: 1;
    border: 1px solid black;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .input-text {
    outline: 0;
    border: 0;
    flex: 1;
    padding-left: 12px;
  }
</style>
