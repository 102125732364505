<template>
  <div>
    <span
      v-if="!loadingProfile && !isLoadingImage"
      class="d-flex align-center flex-start"
    >
      <v-avatar
        class="ma-1"
        :size="$vuetify.breakpoint.mobile? 40 : 50"
        style="border: 1px solid #e0e0e0;"
      >
        <template v-if="!profileImage">
          <v-icon large>
            {{ icons.accountIcon }}
          </v-icon>
        </template>
        <template v-else>
          <v-img :src="profileImage" />
        </template>
      </v-avatar>

      <v-tooltip
        v-if="error && !showOnlyImage"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="name-text mx-4 cursor-pointer"
            v-on="on"
          >
            {{ personId }}
          </div>
        </template>
        <span>{{ $t('deconve.profileNotFound') }}</span>
      </v-tooltip>

      <div
        v-else-if="!showOnlyImage"
        class="name-text mx-4"
      >
        {{ profile.name }}
      </div>
    </span>

    <v-col v-else>
      <v-skeleton-loader
        v-if="showOnlyImage"
        type="avatar"
      />
      <v-skeleton-loader
        v-else
        type="list-item-avatar"
      />
    </v-col>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { downloadImage } from '@/api';
import { mdiAccount } from '@mdi/js';

export default {
  name: 'PersonPreviewLine',
  props: {
    personId: { type: String, required: true },
    showOnlyImage: { type: Boolean, default: false },
    isSuperProfile: { type: Boolean, default: false },
  },
  data() {
    return {
      profileImage: '',
      loadingProfile: false,
      isLoadingImage: false,
      error: undefined,
      icons: {
        accountIcon: mdiAccount,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPerson: 'faceid/getPerson',
      getSuperProfile: 'faceid/getSuperProfile',
    }),
    profile() {
      if (this.isSuperProfile) {
        return this.getSuperProfile(this.personId);
      }

      return this.getPerson(this.personId);
    },
  },
  created() {
    this.loadingProfile = true;
    this.isLoadingImage = true;

    if (this.isSuperProfile) {
      this.fetchSuperProfileData();
    } else {
      this.fetchPersonData();
    }
  },
  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
      fetchSuperProfile: 'faceid/fetchSuperProfile',
    }),
    fetchProfileError() {
      this.loadingProfile = false;
      this.isLoadingImage = false;
      this.error = true;
    },
    fetchProfileSucess() {
      this.loadingProfile = false;
      this.downloadProfileImage();
    },
    fetchSuperProfileData() {
      this.fetchSuperProfile(this.personId).then(() => {
        this.fetchProfileSucess();
      }).catch(() => {
        this.fetchProfileError();
      });
    },
    fetchPersonData() {
      this.fetchPerson(this.personId).then(() => {
        this.fetchProfileSucess();
      }).catch(() => {
        this.fetchProfileError();
      });
    },
    downloadProfileImage() {
      if (this.profile) {
        const { profile_image_url: profileImageUrl } = this.profile;

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
          this.isLoadingImage = false;
        });
      }
    },
  },
};
</script>

<style scoped>
  .name-text {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
