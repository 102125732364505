// Copyright (C) 2021 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig } from 'axios';
import store from '../../store';

import { getDemoNotificationReports, getDemoPersonNotificationStatistics } from '../../store/faceid/reports/demo';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  notificationTags: string[];
  noNotificationTags: boolean;
  peopleTags: string[];
  noPeopleTags: boolean;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  limit?: number;
  skip?: number;
  personId?: string;
  videoId?: string;
  formatType?: string;
  locale?: string;
}

interface ParsedReportQuery {
  start_date: string;
  end_date: string;
  group_by: string;
  search?: string;
  limit?: number;
  sort_by?: string;
  sort_order?: string;
  skip?: number;
  video_id?: string;
  person_id?: string;
  format_type?: string;
  locale?: string;
}

export function getNotificationStatistics() {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_APP_URL,
      url: 'faceid/reports/stats/',
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

export function getPersonNotificationStatistics(personId: string) {
  return new Promise((resolve) => {
    const { authorizationToken, isDemoMode } = store.getters;

    if (isDemoMode) {
      getDemoPersonNotificationStatistics(personId).then((stats) => {
        resolve(stats);
      });
    } else {
      const requestOptions: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: 'faceid/reports/stats/',
        method: 'GET',
        headers: {
          Authorization: authorizationToken,
        },
        params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
          person_id: personId,
        },
      };

      axios(requestOptions).then((response) => {
        const { data } = response;

        resolve(data);
      });
    }
  });
}

function parseTagsToUrlPattern(prefix: string, noTags: boolean, tags: string[]): string {
  if (noTags) {
    return prefix;
  }

  return tags.map((tag) => `${prefix}=${tag}`).join('&');
}

export function getNotificationReports({
  startDate,
  endDate,
  limit,
  skip,
  videoId,
  personId,
  notificationTags,
  noNotificationTags,
  peopleTags,
  noPeopleTags,
  groupBy,
  sortBy,
  search,
  sortOrder,
  formatType,
  locale,
}: ReportQuery) {
  return new Promise((resolve, reject) => {
    const { authorizationToken, isDemoMode } = store.getters;

    let urlParams = parseTagsToUrlPattern('tag_ids', noNotificationTags, notificationTags);
    const peopleTagsParams = parseTagsToUrlPattern('people_tag_ids', noPeopleTags, peopleTags);

    if (urlParams.length > 0 && peopleTagsParams.length > 0) {
      urlParams += '&';
    }

    urlParams += peopleTagsParams;
    const url = urlParams.length > 0 ? `/faceid/reports/?${urlParams}` : '/faceid/reports/';

    const params: ParsedReportQuery = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: startDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: endDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      group_by: groupBy,
    };

    if (search) {
      params.search = search;
    }

    if (limit !== undefined && limit >= 0) {
      params.limit = limit;
    }

    if (sortBy) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.sort_by = sortBy;
    }

    if (sortOrder) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.sort_order = sortOrder;
    }

    if (skip) {
      params.skip = skip;
    }

    if (videoId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.video_id = videoId;
    }

    if (personId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.person_id = personId;
    }

    if (formatType) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.format_type = formatType;
    }

    if (locale) {
      params.locale = locale;
    }

    const requestOptions: AxiosRequestConfig = {
      url,
      params,
      method: 'get',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      headers: {
        Authorization: authorizationToken,
      },
    };

    if (isDemoMode) {
      getDemoNotificationReports(groupBy).then((data) => {
        resolve(data);
      });
    } else {
      axios(requestOptions).then((response) => {
        const { data } = response;

        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    }
  });
}

export default {
  getNotificationStatistics,
  getPersonNotificationStatistics,
  getNotificationReports,
};
