<template>
  <div
    class="d-flex flex-wrap"
    style="flex: 1"
  >
    <v-img
      src="@/assets/low-quality-image-example.png"
      max-width="150px"
      class="mr-1"
      style="flex: 1; border-radius: 4px; justify-content: end; align-items: flex-end"
      content-class="d-flex justify-start"
    >
      <div
        class="d-flex align-center"
      >
        <strong class="ml-2 white--text">{{ $t('deconve.person.image.quality.low') }}</strong>
      </div>
    </v-img>
    <v-img
      src="@/assets/regular-quality-image-example.png"
      max-width="150px"
      class="mr-1"
      style="flex: 1; border-radius: 4px; justify-content: end; align-items: flex-end"
      content-class="d-flex justify-start"
    >
      <div
        class="d-flex align-center"
      >
        <strong class="ml-2 white--text">
          {{ $t('deconve.person.image.quality.regular') }}
        </strong>
      </div>
    </v-img>
    <v-img
      src="@/assets/good-quality-image-example.png"
      color="#e6c200"
      max-width="150px"
      style="flex: 1; border-radius: 4px; justify-content: end; align-items: flex-end"
      content-class="d-flex justify-start"
    >
      <div
        class="d-flex align-center"
      >
        <strong class="ml-2 white--text">{{ $t('deconve.person.image.quality.good') }}</strong>
      </div>
    </v-img>
  </div>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'ImageExamples',
};
</script>
