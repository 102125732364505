<template>
  <div>
    <v-data-iterator
      class="d-flex flex-column mt-4"
      style="height: 100%"
      :items="relatedProfiles"
      :loading="isLoading"
      no-data-text=""
      no-results-text=""
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header>
        <div
          class="d-flex align-center mb-6"
        >
          <v-icon
            class="mr-2"
            color="neutralPrimary"
            :size="$vuetify.breakpoint.mobile? 20 : 24"
          >
            {{ icons.personMultiple }}
          </v-icon>
          <div
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >
            {{ $tc('deconve.relatedProfilesNumber', relatedProfiles.length) }}
          </div>
        </div>
      </template>

      <template v-slot:default="">
        <profile-list-viewer
          v-if="relatedProfiles.length > 0"
          :profiles-to-show="relatedProfiles"
          :is-loading="isLoading"
        />
      </template>
    </v-data-iterator>
  </div>
</template>

<script>

import ProfileListViewer from '@/components/ProfileListViewer.vue';
import { mdiAccountMultipleOutline } from '@mdi/js';

export default {
  name: 'ProfileRelatedPeople',
  components: {
    ProfileListViewer,
  },
  props: {
    relatedProfiles: { type: Array, required: true },
  },
  data() {
    return {
      isLoading: false,
      icons: {
        personMultiple: mdiAccountMultipleOutline,
      },
    };
  },
};

</script>
