<template>
  <v-container>
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="referenceUnits"
      :loading="isLoading || isMonitoringUnitsLoading"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header>
        <div
          dense
          class="d-flex align-center justify-space-between mb-6"
        >
          <input-text
            v-model="search"
            max-width="240px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />
          <v-spacer />
          <square-button
            icon-name="mdi-information-outline"
            :icon-color="showUnitsInfo ? 'white' : 'neutral'"
            :color="showUnitsInfo ? 'info' : 'transparentBackground'"
            menu-disabled
            :tooltip-message="$t('deconve.info')"
            :outlined="!showUnitsInfo"
            fab
            small
            @clicked="showUnitsInfo = !showUnitsInfo"
          />
        </div>
      </template>

      <template v-slot:default="props">
        <v-col>
          <v-progress-linear
            v-if="isLoading || isLoadingMoreReferenceUnits"
            :indeterminate="true"
          />
          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="subtitle-1 font-weight-bold neutralPrimary--text"
            >
              {{ $t('deconve.referenceUnits') }}
            </div>
          </v-row>
          <v-row
            v-if="showUnitsInfo"
            class="mb-4"
          >
            <alert
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.referenceUnitsInfo') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <v-col
              v-for="unit in props.items"
              :key="unit.id"
              :cols="isMobileVersion ? 'auto' : 3"
            >
              <v-card
                elevation="2"
                class="pa-1 mr-2 mb-2"
                width="300px"
                @click="goToUnitPage(unit.id)"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <span class="text-subtitle-2 mb-4">
                      {{ unit.name ? unit.name : unit.id }}
                    </span>

                    <div class="d-flex align-end flex-wrap mb-4">
                      <tag
                        v-for="tag in unit.tags.slice(0, 2)"
                        :key="tag.id"
                        class="pr-1 pt-1"
                        :tag-id="tag.id"
                        small
                        :to-shorten="isToShortenTags(unit.tags)"
                      />

                      <v-tooltip
                        v-if="hasMoreTags(unit.tags)"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              color="neutral"
                              size="18"
                              style="opacity: 0.6;"
                            >
                              {{ icons.dots }}
                            </v-icon>
                          </span>
                        </template>
                        <span>
                          {{ $t('deconve.seeMoreInfo') }}
                        </span>
                      </v-tooltip>
                    </div>

                    <div class="d-flex justify-space-between">
                      <v-list-item-subtitle style="max-width: 250px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                v-if="!isMobileVersion"
                                dense
                              >
                                {{ icons.calendarRange }}
                              </v-icon>
                              <span>
                                {{ unit.created_at }}
                              </span>
                            </span>
                          </template>
                          <span>
                            {{ $t('deconve.monitoringStartDate') }}
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle style="max-width: 50px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                dense
                                color="#54D162"
                              >
                                {{ icons.check }}
                              </v-icon>
                              <span>
                                {{ unit.number_of_positive_notification }}
                              </span>
                            </span>
                          </template>
                          <span>
                            {{ $t('deconve.numberOfPositiveNotifications') }}
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              v-if="hasMoreReferenceUnits"
              color="neutral"
              text
              plain
              elevation="0"
              @click="loadReferenceUnits"
            >
              {{ $t('deconve.loadMore') }}
            </v-btn>
          </v-row>
        </v-col>
      </template>
    </v-data-iterator>

    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="nearbyUnits"
      :loading="isLoading || isMonitoringUnitsLoading"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
      disable-pagination
    >
      <template v-slot:default="props">
        <v-col>
          <v-progress-linear
            v-if="isLoading || isLoadingMoreNearbyUnits"
            :indeterminate="true"
          />
          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="subtitle-1 font-weight-bold neutralPrimary--text"
            >
              {{ $t('deconve.nearbyUnits') }}
            </div>
          </v-row>
          <v-row
            v-if="showUnitsInfo"
            class="mb-4"
          >
            <alert
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.nearbyUnitsInfo') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <v-col
              v-for="unit in props.items"
              :key="unit.id"
              :cols="isMobileVersion ? 'auto' : 3"
            >
              <v-card
                elevation="2"
                class="pa-1 mr-2 mb-2 d-flex flex-column"
                width="300px"
                :height="isMobileVersion ? 'auto' : '100px'"
                @click="goToUnitPage(unit.id)"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <span class="text-subtitle-2 mb-4">
                      {{ unit.name ? unit.name : unit.id }}
                    </span>

                    <div class="d-flex align-end flex-wrap mb-4">
                      <tag
                        v-for="tag in unit.tags.slice(0, 2)"
                        :key="tag.id"
                        class="pr-1 pt-1 tagSize"
                        :tag-id="tag.id"
                        small
                        :to-shorten="isToShortenTags(unit.tags)"
                      />

                      <v-tooltip
                        v-if="hasMoreTags(unit.tags)"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              color="neutral"
                              size="18"
                              style="opacity: 0.6;"
                            >
                              {{ icons.dots }}
                            </v-icon>
                          </span>
                        </template>
                        <span>
                          {{ $t('deconve.seeMoreInfo') }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              v-if="hasMoreNearbyUnits"
              color="neutral"
              text
              plain
              elevation="0"
              @click="loadNearbyUnits"
            >
              {{ $t('deconve.loadMore') }}
            </v-btn>
          </v-row>
        </v-col>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

import Alert from '@/components/Alert.vue';
import InputText from '@/components/InputText.vue';
import SquareButton from '@/components/SquareButton.vue';
import Tag from '@/components/Tag.vue';

import { mdiCalendarRangeOutline, mdiCheck, mdiDotsHorizontal } from '@mdi/js';
import { defaultMonitoringFilterOptions, UNIT_TYPE } from '@/utils/faceidFilters';

export default {
  name: 'ProfileUnits',
  components: {
    Alert,
    InputText,
    SquareButton,
    Tag,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    profileId: { type: String, required: true },
    isPersonRequest: { type: Boolean, required: true },
  },
  data() {
    return {
      nearbyPage: 1,
      referencePage: 1,
      isLoadingMoreNearbyUnits: false,
      isLoadingMoreReferenceUnits: false,
      filterOptions: defaultMonitoringFilterOptions(),
      search: '',
      showUnitsInfo: false,
      referenceUnits: [],
      nearbyUnits: [],
      icons: {
        calendarRange: mdiCalendarRangeOutline,
        check: mdiCheck,
        dots: mdiDotsHorizontal,
      },
    };
  },
  computed: {
    ...mapGetters({
      getReferenceUnitsIds: 'faceid/getReferenceUnitsIds',
      getNearbyUnitsIds: 'faceid/getNearbyUnitsIds',
      getMonitoredReferenceUnits: 'faceid/getMonitoredReferenceUnits',
      hasMoreReferenceUnits: 'faceid/hasMoreReferenceUnits',
      hasMoreNearbyUnits: 'faceid/hasMoreNearbyUnits',
      isMonitoringUnitsLoading: 'faceid/isMonitoringUnitsLoading',
    }),
    getMonitoredReferenceUnitById() {
      return (unitId) => this.getMonitoredReferenceUnits?.find(({ unit }) => unit.id === unitId);
    },
    getNearbyUnitsIdsRef() {
      return this.getNearbyUnitsIds;
    },
    getReferenceUnitsIdsRef() {
      return this.getReferenceUnitsIds;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    isToShortenTags() {
      return (tags) => tags.length >= 2;
    },
    hasMoreTags() {
      return (tags) => tags.length > 2;
    },
  },
  watch: {
    getNearbyUnitsIdsRef() {
      this.fetchNearbyUnitsByIds();
    },
    getReferenceUnitsIdsRef() {
      this.fetchReferenceUnitsByIds();
    },
  },
  created() {
    this.fetchMonitoringReferenceUnits();
    this.fetchMonitoringNearbyUnits();
  },
  methods: {
    ...mapActions({
      fetchUnit: 'units/fetchUnit',
      fetchMonitoredUnits: 'faceid/fetchMonitoredUnits',
    }),
    fetchMonitoringReferenceUnits() {
      this.filterOptions.unitType = UNIT_TYPE.reference;
      const fetchFilters = {
        profileId: this.profileId,
        filtersOptions: this.filterOptions,
        isPersonRequest: this.isPersonRequest,
      };

      this.isLoadingMoreReferenceUnits = true;

      this.fetchMonitoredUnits(fetchFilters);
    },
    fetchMonitoringNearbyUnits() {
      this.filterOptions.unitType = UNIT_TYPE.others;
      const fetchFilters = {
        profileId: this.profileId,
        filtersOptions: this.filterOptions,
        isPersonRequest: this.isPersonRequest,
      };

      this.isLoadingMoreNearbyUnits = true;

      this.fetchMonitoredUnits(fetchFilters);
    },
    loadNearbyUnits() {
      if (this.hasMoreNearbyUnits) {
        this.filterOptions.skip = this.nearbyPage * this.filterOptions.limit;
        this.fetchMonitoringNearbyUnits();

        this.nearbyPage += 1;
      }
    },
    loadReferenceUnits() {
      if (this.hasMoreReferenceUnits) {
        this.filterOptions.skip = this.referencePage * this.filterOptions.limit;
        this.fetchMonitoringReferenceUnits();

        this.referencePage += 1;
      }
    },
    goToUnitPage(unitId) {
      this.$router.push({ name: 'unit-details', params: { unitId } });
    },
    fetchNearbyUnitsByIds() {
      const promises = this.getNearbyUnitsIdsRef.map((id) => new Promise((resolve) => {
        this.fetchUnit(id)
          .then((data) => {
            resolve(data);
          });
      }));

      Promise.all(promises)
        .then((data) => {
          const newNearbyUnits = this.nearbyUnits.concat(data);

          this.nearbyUnits = newNearbyUnits;
          this.isLoadingMoreNearbyUnits = false;
        });
    },
    fetchReferenceUnitsByIds() {
      const promises = this.getReferenceUnitsIdsRef.map((id) => new Promise((resolve) => {
        this.fetchUnit(id)
          .then((data) => {
            const referenceUnitsData = { ...data };
            const monitoredUnitInfo = this.getMonitoredReferenceUnitById(id);

            // eslint-disable-next-line @typescript-eslint/camelcase
            const { number_of_positive_notifications, created_at } = monitoredUnitInfo;

            // eslint-disable-next-line @typescript-eslint/camelcase
            referenceUnitsData.number_of_positive_notification = number_of_positive_notifications;

            // eslint-disable-next-line @typescript-eslint/camelcase
            referenceUnitsData.created_at = moment.utc(created_at).format('DD/MM/YYYY HH:mm');
            resolve(referenceUnitsData);
          });
      }));

      Promise.all(promises)
        .then((data) => {
          const newReferenceUnits = this.referenceUnits.concat(data);

          this.referenceUnits = newReferenceUnits;
          this.isLoadingMoreReferenceUnits = false;
        });
    },
  },
};
</script>
