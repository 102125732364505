<template>
  <v-row>
    <div class="d-flex flex-wrap">
      <template v-if="!isLoading">
        <face-list-content
          v-for="(face, idx) in listOfFaces"
          :key="`${face.name}-${face.info.selected}:${idx}`"
          :face="face.image"
          :enable-similar-person-search="enableSimilarPersonSearch"
          :original-height="face.originalHeight || 0"
          :original-width="face.originalWidth || 0"
          :info="face.info.faces[face.info.selected]"
          :similar-people="similarPeopleFiltered(face) || []"
          :index="face.index"
          @onFaceSelected="onFaceSelected"
        />
      </template>
      <template v-else>
        <v-skeleton-loader
          v-for="n in 5"
          :key="n"
          type="image"
          :style="{
            'aspect-ratio': '1',
            height: $vuetify.breakpoint.mobile? '80px' : '100px',
            width: $vuetify.breakpoint.mobile? '60px' : '80px',
            margin: '4px',
          }"
        />
      </template>
    </div>
  </v-row>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import FaceListContent from './FaceListContent.vue';

export default {
  name: 'FaceList',
  components: {
    FaceListContent,
  },
  props: {
    faces: { type: Array, default: undefined },
    isLoading: { type: Boolean, default: false },
    enableSimilarPersonSearch: { type: Boolean, default: false },
    isEditMode: { type: Boolean, default: false },
  },
  computed: {
    listOfFaces() {
      return this.faces.filter((face) => face.info.selected !== undefined);
    },
    similarPeopleFiltered() {
      return (face) => {
        if (this.isEditMode) {
          const personId = this.$route.params?.personId;

          return face.info.similarPeople?.filter((item) => item.person.id !== personId);
        }

        return face.info.similarPeople;
      };
    },
  },
  methods: {
    onFaceSelected(index) {
      this.$emit('onFaceSelected', index);
    },
  },
};
</script>
